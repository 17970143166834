






































// Core
import { Component, Mixins, Prop, PropSync, Watch } from 'vue-property-decorator'
import Debounce from '@/decorators/Debounce'

// Mixins
import GetStoreMixin from '@/mixins/GetStore'

// Store
import { documentsNamespace } from '@store/documents'

// Types
import { DocumentsActionTypes } from '@store/documents/Types'

// Interfaces
import { Paginate, RequestParams } from '@/store/interfaces'
import { IDocument, IDocumentType } from '@store/documents/Interface'

@Component({
  name: 'DialogChooseFile',

  components: {
    'v-scroll-list': () => import('@components/ScrollList.vue'),
  },
})
export default class DialogChooseFileComponent extends Mixins(GetStoreMixin) {
  @PropSync('visible', Boolean) private _visible!: boolean

  @Prop({ default: () => [] }) private oldArray!: number[]

  @documentsNamespace.Action(DocumentsActionTypes.A_DOCUMENTS_INCOMING)
  private fetchDocumentsIncoming!: (params: RequestParams) => Promise<Paginate<IDocument>>

  private search = ''
  private page: number = 1
  private documents: IDocument[] = []
  private checkedDocumentsIds: number[] = []
  private lastPage: number = 0

  private mounted() {
    this.fetchDocumentsIncoming({ projectId: this.selectedProject }).then(documents => {
      this.documents = documents.data
      this.lastPage = documents.meta.lastPage
    })
  }

  @Debounce(1000)
  private handleChangeSearch() {
    if (this.search.length >= 3 || this.search.length === 0) {
      this.fetchDocumentsIncoming({ search: this.search, projectId: this.selectedProject }).then(documents => {
        this.documents = documents.data
        this.lastPage = documents.meta.lastPage
      })
    }
  }

  private loadMore() {
    if (this.lastPage > this.page) {
      this.page += 1
      this.fetchDocumentsIncoming({ search: this.search, projectId: this.selectedProject, page: this.page }).then(
        documents => {
          this.documents.push(...documents.data)
        },
      )
    }
  }

  private handleDone() {
    this._visible = false
    this.$emit('changed', this.checkedDocumentsIds)
  }

  private handleCancel() {
    this._visible = false
    this.checkedDocumentsIds = this.oldArray
  }
}
